import React from "react";

export default function ContributionDetailCard({
  title,
  date_of_announcement,
  event_type,
  image_urls,
  description,
}: {
  title: string;
  date_of_announcement: string;
  event_type: string;
  image_urls: string;
  description: string;
}) {
  const [hover, setHover] = React.useState<boolean>(false);

  const handleMouseEnter = () => {
    setTimeout(() => {
      setHover(true);
    }, 200);
  };

  const handleMouseLeave = () => {
    setTimeout(() => {
      setHover(false);
    }, 200);
  };

  return (
    <div
      className="hover:parent-hover relative mr-5 mt-5 flex aspect-[11/9] w-[280px] shrink-0 cursor-pointer rounded-2xl bg-cover bg-center drop-shadow-md duration-200 ease-in md:aspect-[6.5/7] lg:mt-10 lg:duration-500 lg:hover:-translate-y-2"
      style={{
        backgroundImage: `url(${image_urls})`,
      }}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <div className="mt-auto w-full rounded-b-2xl bg-black/[.5] p-2 lg:p-5">
        <h2 className="mt-auto text-[1rem] font-semibold text-white md:text-lg">
          {title}
        </h2>
        <div className="mt-1 flex items-center space-x-3">
          <p className="text-[.7rem] text-gray-400 lg:text-sm">
            {date_of_announcement}
          </p>
          <p
            className={`rounded-xl border-[1.5px] border-[#FFC1C1] px-2 py-[.1rem] text-[.6rem] font-medium text-[#FFC1C1] md:py-1 md:text-[.65rem]`}
          >
            {event_type}
          </p>
        </div>
        {hover && (
          <p className="my-4 text-xs text-white transition-opacity md:text-sm">
            {description.slice(0, 200)}...
          </p>
        )}
      </div>
    </div>
  );
}

// import library
import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter, Routes, Route } from "react-router-dom";

// import pages
import AboutUs from "./pages/AboutUs.tsx";
import Commumity from "./pages/Community.tsx";
import ContributionAll from "./pages/ContributionAll.tsx";
import ContributionDetail from "./pages/ContributionDetail.tsx";
import ScrollToTop from "./components/ScrollToTop.tsx";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <BrowserRouter>
    <ScrollToTop></ScrollToTop>
    <Routes>
      <Route path="/" exact element={<AboutUs />}></Route>
      <Route path="/community" exact element={<Commumity />}></Route>
      <Route path="/contribution" exact element={<ContributionAll />}></Route>
      <Route
        path="/contribution/:id"
        exact
        element={<ContributionDetail />}
      ></Route>
    </Routes>
  </BrowserRouter>,
);

import "../index.css";
import React, { useState, useEffect } from "react";
import axios from "axios";
import Header from "../components/Header.tsx";
import Footer from "../components/Footer.tsx";
import { Link } from "react-router-dom";
import ContributionCard from "../components/contribution/ContributionCard.tsx";

import { ContributionType } from "../data/contribution";
import { description } from "../data/contribution.ts";
import LazyBackground from "../components/LazyLoading.tsx";

export default function ContributionAll() {
  const [contibutions, setAllContributions] = useState<ContributionType[]>([]);
  const [showMore, setShowMore] = useState<number>(0);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`/api/events`);
        setAllContributions(response.data.data);
      } catch (error) {
        console.error(`Error fetching data ${error}`);
      }
    };

    fetchData();
  }, []);

  const handleScroll = (): void => {
    if (
      window.innerHeight + document.documentElement.scrollTop + 300 >=
      document.documentElement.scrollHeight
    ) {
      setTimeout(() => {
        setShowMore((prev) => prev + 6);
      }, 1000);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
  }, []);

  return (
    <div className="overflow-x-hidden">
      <Header></Header>

      <LazyBackground
        className="h-[52vh] bg-cover bg-right md:h-[60vh] xl:h-[70vh] xl:bg-fixed xl:bg-[center_bottom_7rem] 4xl:h-[60vh]"
        src="./image/Contribution.jpg"
        children={
          <div className="flex h-[50vh] flex-col items-center justify-center backdrop-brightness-90 4xl:h-256"></div>
        }
      />

      <div className="mt-10 flex-col pl-10 pr-6 lg:flex lg:flex-row lg:px-20 lg:py-10">
        <div className="flex-column w-12/12">
          <h1 className="mt-5 text-4xl text-[#727272] md:text-5xl">
            Our Contributions
          </h1>
          <p className="mt-8 text-lg text-neutral-500 sm:leading-8 sm:tracking-wide lg:mt-10">
            {description}
          </p>
        </div>
      </div>

      <h1 className="px-10 pt-10 text-4xl text-[#727272] md:text-5xl lg:px-20 lg:pt-20">
        Club Library
      </h1>

      <div className="flex flex-col items-center justify-center px-5">
        <div className="relative grid grid-cols-1 justify-items-center gap-x-24 rounded-2xl px-10 pb-12 sm:grid-cols-2 lg:mx-[2.8vmax] lg:grid-cols-3 lg:gap-x-32 lg:pb-20 lg:pt-5">
          {contibutions.slice(0, showMore).map((contribution, index) => (
            <Link to={`/contribution/${contribution.alias}`} key={index}>
              <ContributionCard
                title={contribution.title}
                date_of_announcement={contribution.date_of_announcement}
                event_type={contribution.event_type}
                image_urls={contribution.image_urls}
                description={contribution.description}
              ></ContributionCard>
            </Link>
          ))}
        </div>

        <div className="flex items-center justify-center space-x-2">
          <span className="sr-only">Loading...</span>
          <div className="h-5 w-5 animate-bounce rounded-full bg-[#FFBE0B] [animation-delay:-0.3s] md:h-7 md:w-7"></div>
          <div className="h-5 w-5 animate-bounce rounded-full bg-[#FF3333] [animation-delay:-0.15s] md:h-7 md:w-7"></div>
          <div className="h-5 w-5 animate-bounce rounded-full bg-[#8338EC] md:h-7 md:w-7"></div>
        </div>
      </div>

      <br></br>
      <Footer></Footer>
    </div>
  );
}

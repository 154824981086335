import React, { useEffect, useState } from "react";
import axios from "axios";

import Header from "../components/Header.tsx";
import Footer from "../components/Footer.tsx";
import "../index.css";

import MemberCard from "../components/community/MemberCard.tsx";

import { githubType, description, quote, core } from "../data/community.ts";
import { Link } from "react-router-dom";

import LazyBackground from "../components/LazyLoading.tsx";

export default function Community() {
  const [isVisible, setIsVisible] = useState<Boolean>(false);
  const [githubs, setGithubs] = useState<githubType[]>([]);
  const randomGithubs = githubs.sort(() => Math.random() - 0.5);

  const handleScroll = (): void => {
    setIsVisible(true);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get("/api/members");
        setGithubs(response.data.data);
      } catch (error) {
        console.error(`Error fetching data ${error}`);
      }
    };

    fetchData();
  }, []);

  return (
    <div className="overflow-x-hidden">
      <Header></Header>

      <LazyBackground
        className="h-[52vh] bg-cover bg-right md:h-[60vh] xl:h-[70vh] xl:bg-fixed xl:bg-[center_bottom_7rem] 4xl:h-[60vh]"
        src="./image/Community.jpg"
        children={
          <div className="flex h-[52vh] flex-col items-center justify-center bg-cover backdrop-brightness-50 md:h-[60vh] xl:h-[70vh] 4xl:h-[60vh]">
            <h1 className="font-semi-bold text-mb tracking-wider text-gray-200 sm:text-5xl md:text-6xl lg:text-7xl xl:text-8xl">
              Our community
            </h1>
            <div className="mx-auto mt-7 w-9/12 text-center lg:w-7/12">
              <p className="md:text-md text-sm text-gray-200 sm:text-sm lg:text-lg xl:text-xl 4xl:text-2xl">
                {description}
              </p>
            </div>
          </div>
        }
      />

      <div
        className={`relative flex-col items-center justify-center bg-fixed transition-all ${
          isVisible ? "bg-purple-200" : "bg-transparent"
        } delay-500 ease-in`}
      >
        <h1
          className={`transition-opacity ${
            isVisible ? "opacity-100" : "opacity-0"
          } top-10 hidden text-[#998db6a6] delay-100 lg:absolute lg:left-[12%] xl:inline-block xl:text-qm 3xl:left-[13%] 3xl:top-10 3xl:text-qml`}
        >
          “
        </h1>
        <h1
          className={`transition-opacity ${
            isVisible ? "opacity-100" : "opacity-0"
          } top-32 hidden text-[#998db6a6] delay-100 lg:absolute lg:right-[12%] xl:inline-block xl:text-qm 3xl:right-[13%] 3xl:top-36 3xl:text-qml`}
        >
          ”
        </h1>
        <div
          className={`transition-opacity ${
            isVisible ? "opacity-100" : "opacity-0"
          } flex items-center justify-center py-24 delay-200 ease-in lg:py-52 xl:px-96 3xl:py-64`}
        >
          <h1 className="w-5/6 text-center text-[1.4rem] font-medium text-neutral-700 md:text-2xl lg:w-[90vw] lg:text-4xl lg:leading-commu lg:tracking-widest xl:text-5xl 3xl:text-6xl 4xl:w-5/6">
            <span className="text-5xl text-[#998db6a6] xl:hidden">“</span>
            {quote}
            <span className="text-5xl text-[#998db6a6] xl:hidden">”</span>
          </h1>
        </div>
      </div>

      <div className="absolute right-0 -z-50 mt-5 flex max-w-lg flex-col bg-transparent lg:mt-12">
        <div className="absolute right-0 top-0 -z-30 h-18 w-9 rounded-bl-full rounded-tl-full bg-[#FFEBB5] shadow-lg lg:right-0 lg:top-0 lg:h-60 lg:w-32"></div>
        <div className="absolute right-3 top-6 -z-20 h-14 w-14 rounded-full bg-[#FFC1C1] shadow-lg lg:right-2 lg:top-24 lg:h-56 lg:w-56"></div>
        <div className="absolute right-0 top-10 -z-10 h-18 w-9 rounded-bl-full rounded-tl-full bg-[#DAC3F9] shadow-lg lg:right-0 lg:top-52 lg:h-60 lg:w-32"></div>
      </div>

      <div className="flex-col items-center justify-center">
        <div className="hidden lg:inline-block lg:w-[4/6] lg:px-32 lg:pb-32 lg:pt-44">
          <h1 className="text-3xl text-neutral-600 lg:text-7xl">
            {" "}
            What we strive to be
          </h1>
        </div>

        <div className="grid grid-cols-1 justify-items-center gap-y-10 lg:grid-cols-3 lg:px-20">
          <div className="w-[78vw] pt-32 sm:pt-16 lg:hidden">
            <h1 className="pt-10 text-[2rem] text-[#727272] md:text-5xl">
              {" "}
              What we strive to be
            </h1>
          </div>

          <div className="flex flex-col items-center justify-center">
            <div
              className="z-20 mb-5 h-[20vh] w-[78vw] rounded-3xl bg-cover bg-center shadow-xl brightness-90 md:w-[65vw] lg:w-64 4xl:w-96"
              style={{ backgroundImage: `url("./image/innovationImage.avif")` }}
            >
              <div className="z-30 mb-5 flex h-[20vh] w-[78vw] items-center justify-center rounded-3xl p-10 md:w-[65vw] lg:w-64 lg:bg-black/[.4] 4xl:w-96">
                <p className="hidden text-2xl font-bold text-white lg:inline-block">
                  Innovation
                </p>
              </div>
            </div>
            <li className="hidden lg:flex lg:w-full lg:items-center lg:justify-center lg:dark:after:border-gray-400">
              <span className="mt-5 hidden animate-spin lg:flex lg:h-12 lg:w-12 lg:shrink-0 lg:items-center lg:justify-center lg:rounded-full lg:border-8 lg:border-[#FFC1C1] lg:bg-[#FF3333]"></span>
            </li>
            <div className="w-[78vw] pl-4 lg:flex lg:w-88 lg:items-center lg:justify-center lg:pl-0">
              <h2 className="mt-3 text-center text-2xl lg:hidden">
                Innovative
              </h2>
              <div className="z-20 w-[70vw] bg-transparent pb-2 pt-5 text-center text-gray-600 md:w-[76vw] lg:w-88 lg:p-16 lg:text-lg 4xl:w-96">
                {core[0]}
              </div>
            </div>
          </div>

          <div className="flex flex-col items-center justify-center">
            <div
              className="z-20 mb-5 h-[20vh] w-[78vw] rounded-3xl bg-cover bg-center shadow-xl md:w-[65vw] lg:w-64 4xl:w-96"
              style={{ backgroundImage: `url("./image/inclusiveImage.avif")` }}
            >
              <div className="z-30 mb-5 flex h-[20vh] w-[78vw] items-center justify-center rounded-3xl p-10 md:w-[65vw] lg:w-64 lg:bg-black/[.5] 4xl:w-96">
                <p className="hidden text-2xl font-bold text-white lg:inline-block">
                  Inclusive
                </p>
              </div>
            </div>
            <li className="hidden lg:flex lg:w-full lg:items-center lg:justify-center lg:dark:after:border-gray-400">
              <span className="mt-5 hidden lg:flex lg:h-12 lg:w-12 lg:shrink-0 lg:items-center lg:justify-center lg:rounded-full lg:border-8 lg:border-[#DAC3F9] lg:bg-[#8338EC]"></span>
            </li>
            <div className="w-[78vw] pl-4 lg:flex lg:w-88 lg:items-center lg:justify-center lg:pl-0">
              <h2 className="mt-3 text-center text-2xl lg:hidden">Inclusive</h2>
              <div className="z-20 w-[70vw] bg-transparent pb-2 pt-5 text-center text-gray-600 md:w-[76vw] lg:w-88 lg:p-16 lg:text-lg 4xl:w-96">
                {core[1]}
              </div>
            </div>
          </div>

          <div className="flex flex-col items-center justify-center">
            <div
              className="z-20 mb-5 h-[20vh] w-[78vw] rounded-3xl bg-cover bg-center shadow-xl brightness-90 md:w-[65vw] lg:w-64 4xl:w-96"
              style={{ backgroundImage: `url("./image/impactfulImage.avif")` }}
            >
              <div className="z-30 mb-5 flex h-[20vh] w-[78vw] items-center justify-center rounded-3xl p-10 md:w-[65vw] lg:w-64 lg:bg-black/[.4] 4xl:w-96">
                <p className="hidden text-2xl font-bold text-white lg:inline-block">
                  Impactful
                </p>
              </div>
            </div>
            <li className="hidden lg:flex lg:w-full lg:items-center lg:justify-center lg:dark:after:border-gray-400">
              <span className="mt-5 hidden lg:flex lg:h-12 lg:w-12 lg:shrink-0 lg:items-center lg:justify-center lg:rounded-full lg:border-8 lg:border-[#FFEBB5] lg:dark:bg-[#FFBE0B]"></span>
            </li>
            <div className="w-[78vw] pl-4 lg:flex lg:w-88 lg:items-center lg:justify-center lg:pl-0">
              <h2 className="mt-3 text-center text-2xl lg:hidden">Impactful</h2>
              <div className="z-20 w-[70vw] bg-transparent pb-2 pt-5 text-center text-gray-600 md:w-[76vw] lg:w-88 lg:p-16 lg:text-lg 4xl:w-96">
                <p className="h-[100%] w-[100%]">{core[2]}</p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="bg-transparant absolute left-0 -z-50 mt-3 flex max-w-lg flex-col lg:-mt-80">
        <div className="absolute -left-5 top-0 -z-10 h-20 w-20 rounded-full bg-[#FFC1C1] shadow-lg lg:-left-40 lg:top-52 lg:h-60 lg:w-60"></div>
        <div className="lg:left-18 absolute left-12 top-14 -z-0 h-16 w-16 rounded-full bg-[#FFEBB5] shadow-lg lg:top-96 lg:h-48 lg:w-48"></div>
        <div className="absolute -left-4 top-10 -z-20 h-24 w-24 rounded-full bg-[#DAC3F9] shadow-lg lg:-left-20 lg:top-72 lg:h-60 lg:w-60"></div>
      </div>

      <div>
        <h1 className="pb-5 pt-60 text-center text-[2rem] text-[#727272] sm:pt-52 md:text-5xl lg:pb-10 lg:pt-80 lg:text-7xl">
          {" "}
          Meet Our Members
        </h1>
      </div>

      <div className="animated-background w-screen bg-gradient-to-br from-[#FFC1C1] via-[#FFEBB5] to-[#DAC3F9]">
        <div className="relative grid h-[76vh] w-[100vw] grid-cols-5 justify-items-center gap-x-16 gap-y-12 overflow-y-auto overflow-x-hidden px-10 pb-28 pt-20">
          {randomGithubs.map((githubProfile, index) => (
            <Link
              key={index}
              to={githubProfile.profile_url ? githubProfile.profile_url : "#"}
              target={githubProfile.profile_url ? "_blank" : "_self"}
            >
              <MemberCard
                id={githubProfile.id}
                name={githubProfile.name}
                role={githubProfile.role}
                profileImage={githubProfile.profile_image_url}
                githubUrl={githubProfile.profile_url}
              />
            </Link>
          ))}
        </div>
      </div>

      <br></br>
      <Footer></Footer>
    </div>
  );
}

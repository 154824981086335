export type githubType = {
  id: number;
  name: string;
  role: string;
  profile_url: string;
  profile_image_url: string;
};

export const description: string =
  "SINNO Club, we empower students to build their knowledge and gain hands-on experience through dynamic projects, embodying the spirit of 'learning by doing.";

export const quote: string =
  "Learn by doing and grow through hands-on projects at SINNO Club.";

export const core: string[] = [
  "We push boundaries to create new ideas and impactful solutions through creative projects, helping members develop technical skills and address challenges which is helpful for real world scenarios.",
  "Our club brings together individuals from diverse backgrounds to collaborate on projects, fostering teamwork and collective learning. We create an environment where everyone can contribute, grow, and achieve common goals.",
  "Our club makes a significant impact on the community by engaging in collaborative projects and dedicated efforts. Through our initiatives, we drive positive change helping our community grow and thrive subsequentially.",
];

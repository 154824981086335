import React, { useState, useEffect, useCallback } from "react";
import { GoTriangleLeft, GoTriangleRight } from "react-icons/go";

export default function Carousel({
  slides,
  header,
}: {
  slides: { image: string }[];
  header: boolean;
}) {
  const [curr, setCurr] = useState<number>(0);

  const nextSlide = useCallback(() => {
    setCurr((curr) => (curr === slides.length - 1 ? 0 : curr + 1));
  }, [slides.length]);

  const prevSlide = useCallback(() => {
    setCurr((curr) => (curr === 0 ? slides.length - 1 : curr - 1));
  }, [slides.length]);

  useEffect(() => {
    const slideInterval = setInterval(nextSlide, 6000);
    return () => clearInterval(slideInterval);
  }, [nextSlide]);

  return (
    <div
      className={`relative h-full overflow-hidden ${
        header ? "" : "rounded-3xl shadow-md"
      }`}
    >
      <div
        className="relative flex h-full w-full transition-transform duration-500 ease-out"
        style={{ transform: `translateX(-${curr * 100}%)` }}
      >
        {slides.map((item, index) => {
          return (
            <div key={index} className="relative h-full w-full">
              <div className="h-full w-full">
                <div
                  className="aspect-[6.5/1] h-full w-full bg-cover bg-center"
                  style={{ backgroundImage: `url(${item.image})` }}
                ></div>
              </div>
              <div className="text-2xl font-bold text-white md:text-4xl">
                <div className="absolute inset-0 flex h-full w-full items-center justify-center bg-black bg-opacity-[20%]"></div>
              </div>
            </div>
          );
        })}
      </div>

      <div
        className={`${
          header ? "" : "hidden"
        }absolute top-0 flex h-full w-full items-center justify-between px-6 text-7xl text-white`}
      >
        <button
          onClick={prevSlide}
          className="hidden duration-100 ease-in hover:text-gray-400 active:text-gray-600 lg:block"
        >
          <GoTriangleLeft className="text-transparent hover:text-gray-400" />
        </button>
        <button
          onClick={nextSlide}
          className="hidden duration-100 ease-in hover:text-gray-400 active:text-gray-600 lg:block"
        >
          <GoTriangleRight className="text-transparent hover:text-gray-400" />
        </button>
      </div>
      <div className="absolute bottom-4 left-0 right-0">
        <div className="flex items-center justify-center gap-2">
          {slides.map((slide, i) => (
            <div
              key={i}
              onClick={() => {
                setCurr(Number(slide));
              }}
              className={`h-2 w-2 cursor-pointer rounded-full transition-all md:h-3 md:w-3 ${
                Number(slide) ? "bg-[#646464]" : "border border-[#646464]"
              }`}
            ></div>
          ))}
        </div>
      </div>
    </div>
  );
}

import React, { useEffect, useState } from "react";
import Carousel from "../components/Carousel.tsx";
import Header from "../components/Header.tsx";
import Footer from "../components/Footer.tsx";
import Contact from "../components/Contact.tsx";

import { useSpring, animated } from "react-spring";
import { FaArrowRightLong } from "react-icons/fa6";
import "../index.css";

import {
  AboutSinno,
  AnnouncementType,
  ContributionType,
  memberType,
} from "../data/aboutUs.ts";

import axios from "axios";
import { Link } from "react-router-dom";
import ContributionCard from "../components/aboutUs/ContributionCard.tsx";

function Number({ n }) {
  const { number } = useSpring<{
    from: {
      number: number;
    };
    number: any;
    delay: number;
    config: {
      mass: number;
      tension: number;
      friction: number;
    };
  }>({
    from: { number: 0 },
    number: n,
    delay: 200,
    config: { mass: 1, tension: 20, friction: 10 },
  });
  return <animated.div>{number.to((n) => n.toFixed(0))}</animated.div>;
}

export default function AboutUs() {
  const [annoucements, setAnnouncements] = useState<AnnouncementType[]>([]);
  const [contributions, setContributions] = useState<ContributionType[]>([]);
  const [MemberLength, setMemberLength] = useState<number>();

  useEffect(() => {
    axios
      .get<AnnouncementType[]>("/api/announcements")
      .then((res) => setAnnouncements(res.data.data))
      .catch((err) => console.error(`Error fetching data ${err}`));
  }, []);

  useEffect(() => {
    axios
      .get<ContributionType[]>("/api/events")
      .then((res) => setContributions(res.data.data))
      .catch((err) => console.error(`Error fetching data ${err}`));
  }, []);

  useEffect(() => {
    axios
      .get<memberType[]>("/api/members")
      .then((res) => setMemberLength(res.data.data.length))
      .catch((err) => console.error(`Error fetching data ${err}`));
  }, []);

  const slides = annoucements
    ?.map((project) => ({
      id: project.id.toString(),
      image: project.image_urls,
      title: project.title,
    }))
    .flat();

  return (
    <div>
      <Header></Header>

      {/*Poster Slider*/}
      <div className="h-[52vh] w-full md:h-[60vh] xl:h-[70vh] 4xl:h-[60vh]">
        <Carousel slides={slides.map((slide) => slide)} header={true} />
      </div>

      {/*Stats*/}
      <div className="mx-auto mt-3 flex w-full items-center justify-center gap-6 py-5 text-center md:mt-8 md:w-[70%] md:gap-10 md:py-8 xl:w-[50%]">
        {/*Members*/}
        <div className="flex aspect-square w-[25.7%] flex-col items-center justify-center gap-2 rounded-2xl bg-[#FFEBB5] shadow-md md:w-[30%] lg:w-[25%]">
          <div className="flex aspect-square w-1/2 items-center justify-center rounded-full bg-[#FFBE0B] text-xl font-bold text-white md:text-2xl lg:text-3xl 4xl:text-4xl">
            <Number n={MemberLength} />
          </div>
          <p className="text-base text-[#7B7B7B] md:text-xl lg:text-2xl">
            Members
          </p>
        </div>

        {/*Projects*/}
        <div className="flex aspect-square w-[25.7%] flex-col items-center justify-center gap-2 rounded-2xl bg-[#FFC1C1] shadow-md md:w-[30%] lg:w-[25%]">
          <div className="flex aspect-square w-1/2 items-center justify-center rounded-full bg-[#FF3333] text-xl font-bold text-white md:text-2xl lg:text-3xl 4xl:text-4xl">
            <Number
              n={
                contributions.filter(
                  (contribution) =>
                    contribution.event_type.toLowerCase() === "project",
                ).length
              }
            />
          </div>
          <p className="text-base text-[#7B7B7B] md:text-xl lg:text-2xl">
            Projects
          </p>
        </div>

        {/*Activities*/}
        <div className="flex aspect-square w-[25.7%] flex-col items-center justify-center gap-2 rounded-2xl bg-[#DAC3F9] shadow-md md:w-[30%] lg:w-[25%]">
          <div className="flex aspect-square w-1/2 items-center justify-center rounded-full bg-[#8338EC] text-xl font-bold text-white md:text-2xl lg:text-3xl 4xl:text-4xl">
            <Number
              n={
                contributions.filter(
                  (contribution) =>
                    contribution.event_type.toLowerCase() !== "project",
                ).length
              }
            />
          </div>
          <p className="text-base text-[#7B7B7B] md:text-xl lg:text-2xl">
            Activities
          </p>
        </div>
      </div>

      {/*About Sinno*/}

      <div className="my-6 flex w-full flex-col-reverse md:my-10 md:grid md:grid-cols-2">
        {/*Left container*/}
        <div className="flex flex-col">
          {/*Rotated Image*/}
          <div className="hidden justify-center md:flex">
            <div
              className="my-10 ml-24 aspect-square w-[80%] overflow-hidden rounded-full bg-slate-200 bg-cover bg-center shadow-2xl 4xl:w-[60%]"
              style={{ backgroundImage: `url("./image/aboutus-pic.png")` }}
            ></div>
          </div>

          {/*Core values*/}
          <div className="my-14 flex flex-col items-end">
            <div className="mr-[45%] flex h-48 w-48 animate-shake flex-col items-center justify-center rounded-full bg-[#FFC1C1] shadow-lg animate-duration-[4500ms] animate-infinite animate-ease-linear md:mr-[10%]">
              <div className="h-24 w-24 rounded-full bg-[#FF3333]"></div>
              <h3 className="mt-3 text-lg text-[#7A7A7A]">Creative</h3>
            </div>
            <div className="mr-[5%] flex h-44 w-44 animate-shake flex-col items-center justify-center rounded-full bg-[#FFEBB5] shadow-lg animate-duration-[4000ms] animate-infinite animate-ease-linear md:mr-[50%] lg:-mt-14">
              <div className="h-[5.5rem] w-[5.5rem] rounded-full bg-[#FFBE0B]"></div>
              <h3 className="mt-3 text-lg text-[#7A7A7A]">Collaborative</h3>
            </div>
            <div className="mr-[45%] mt-3 flex h-[11.5rem] w-[11.5rem] animate-shake flex-col items-center justify-center rounded-full bg-[#DAC3F9] shadow-lg animate-duration-[3500ms] animate-infinite animate-ease-linear md:mr-[20%] md:h-40 md:w-40 lg:-mt-4">
              <div className="h-[5.75rem] w-[5.75rem] rounded-full bg-[#8338EC] md:h-20 md:w-20"></div>
              <h3 className="mt-3 text-[1.1rem] text-[#7A7A7A] md:mt-2">
                Project-Driven
              </h3>
            </div>
          </div>
        </div>

        {/*Right container*/}
        <div className="relative">
          <div className="sticky top-0 p-5 text-center text-[#727272] md:p-10 md:text-right lg:p-20">
            <h1 className="mb-5 mt-8 text-5xl md:mt-20 lg:text-6xl">
              About SINNO
            </h1>
            <p className="px-3 text-left text-lg leading-7 md:leading-10 lg:text-right 3xl:text-xl 3xl:leading-loose">
              {AboutSinno}
            </p>
          </div>
        </div>
      </div>

      {/*Our work*/}
      <div className="mb-16 mt-10 overflow-hidden">
        <h2 className="ml-10 text-4xl text-[#727272] md:ml-20 md:text-5xl">
          Club Library
        </h2>

        <div className="mt-6 h-full w-full bg-[#FFEBB5] px-6 py-6 md:px-20">
          <div className="scrollbar-thumb flex items-center overflow-x-scroll pb-5 scrollbar-thin scrollbar-track-[#FFBE0B] scrollbar-thumb-white scrollbar-track-rounded-full scrollbar-thumb-rounded-full">
            {contributions
              .sort(() => Math.random() - 0.5)
              .map((contribution, index) => (
                <div className="h-[370px] w-[350px] shrink-0 cursor-pointer overflow-y-hidden rounded-2xl px-9 py-6 transition duration-300 hover:bg-[#FFF8E5] md:h-[400px] md:w-[25vmax] md:min-w-[370px] 3xl:h-[500px]">
                  <Link to={`/contribution/${contribution.alias}`}>
                    <ContributionCard
                      key={index}
                      title={contribution.title}
                      image_urls={contribution.image_urls}
                      description={contribution.description}
                    />
                  </Link>
                </div>
              ))}
          </div>

          <div className="mt-6 flex justify-end">
            <Link to="/contribution">
              <button className="flex items-center gap-2 rounded-full py-2 text-center text-lg text-[#727272] duration-150 ease-in hover:scale-105 md:bg-white md:px-8 md:text-xl md:shadow-md">
                View All
                <div className="md:hidden">
                  <FaArrowRightLong />
                </div>
              </button>
            </Link>
          </div>
        </div>
      </div>

      <div className="flex justify-center md:mt-40">
        <h2 className="text-[5vmax] text-[#727272] md:text-6xl">
          Connect with us
        </h2>
      </div>

      <Contact></Contact>

      <Footer></Footer>
    </div>
  );
}

import React from "react";
import { Link } from "react-router-dom";
import { FaInstagram, FaLinkedin } from "react-icons/fa6";
import { FaGithub } from "react-icons/fa";

export default function Contact() {
  return (
    <div className="overflow-x-hidden">
      <div className="mb-10 flex w-full items-center p-2 pt-10 md:h-full md:pt-24">
        <div className="m-auto h-full w-full grid-cols-2 gap-x-10 px-5 md:grid md:px-10">
          <div className="pb-12">
            <div
              className="relative h-96 w-full rounded-xl bg-cover bg-right drop-shadow-lg xl:h-128 4xl:h-128"
              style={{ backgroundImage: `url("./image/contactUs.avif")` }}
            >
              <div className="relative h-96 w-full rounded-xl bg-gray-100/[0.25] xl:h-128 4xl:h-128">
                <div className="absolute bottom-0 w-[100%] pb-8 pl-[4rem]">
                  <div className="flex-col justify-center">
                    <div className="flex content-between">
                      <img
                        className="mt-[5px] h-full w-full max-w-[24px] flex-initial"
                        src="https://cdn.iconscout.com/icon/free/png-256/free-mail-1437261-1216865.png"
                        alt="insert gmail here"
                      ></img>
                      <h1 className="text- flex-initial pl-2 pt-1 text-left font-medium text-neutral-600 md:text-xl">
                        {" "}
                        siit.innoclub@gmail.com{" "}
                      </h1>
                    </div>

                    <div className="w-[100%]">
                      <div className="items-left m-auto flex h-auto flex-col justify-center rounded-xl">
                        <ul className="mt-2 grid w-[80%] max-w-fit grid-cols-3 place-content-start gap-3 md:w-[100%]">
                          <li className="duration-10 flex cursor-pointer justify-center rounded-full border border-[#7B7B7B] px-6 py-2 pt-2.5 ease-in hover:bg-[#EEEEEE]">
                            <Link
                              to="https://www.instagram.com/sinnoclub.official/"
                              target="_blank"
                              rel="noreferrer"
                              className="text-xl"
                            >
                              <FaInstagram></FaInstagram>
                            </Link>
                          </li>

                          <li className="duration-10 flex cursor-pointer justify-center rounded-full border border-[#7B7B7B] px-2 py-2 pt-2.5 ease-in hover:bg-[#EEEEEE] md:px-6">
                            <Link
                              to="https://www.linkedin.com/company/siit-innovation-club/mycompany/"
                              target="_blank"
                              rel="noreferrer"
                              className="text-xl"
                            >
                              <FaLinkedin></FaLinkedin>
                            </Link>
                          </li>

                          <li className="duration-10 flex cursor-pointer justify-center rounded-full border border-[#7B7B7B] px-6 py-2 pt-2.5 ease-in hover:bg-[#EEEEEE]">
                            <Link
                              to="https://github.com/SIITInnovationClub/"
                              target="_blank"
                              rel="noreferrer"
                              className="text-xl"
                            >
                              <FaGithub></FaGithub>
                            </Link>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="flex-col items-center justify-center md:px-0">
            <h1 className="pb-4 text-2xl tracking-wide text-neutral-600 md:text-3xl">
              Please fill to contact us
            </h1>

            <form className="flex-col items-center justify-center">
              <div className="mb-6 grid gap-6 md:grid-cols-2">
                <div>
                  <label
                    htmlFor="first_name"
                    className="mb-2 block text-sm font-medium italic text-neutral-600 dark:text-neutral-600"
                  >
                    First name
                  </label>
                  <input
                    type="text"
                    id="first_name"
                    className="block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-sm text-gray-900 focus:border-blue-500 focus:ring-blue-500 dark:border-gray-400 dark:bg-gray-100 dark:text-neutral-600 dark:placeholder-gray-400 dark:focus:border-blue-500 dark:focus:ring-blue-500"
                    placeholder="siit"
                    required
                  />
                </div>
                <div>
                  <label
                    htmlFor="last_name"
                    className="mb-2 block text-sm font-medium italic text-neutral-600 dark:text-neutral-600"
                  >
                    Last name
                  </label>
                  <input
                    type="text"
                    id="last_name"
                    className="block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-sm text-gray-900 focus:border-blue-500 focus:ring-blue-500 dark:border-gray-400 dark:bg-gray-100 dark:text-neutral-600 dark:placeholder-gray-400 dark:focus:border-blue-500 dark:focus:ring-blue-500"
                    placeholder="innoclub"
                    required
                  />
                </div>
              </div>
              <div className="mb-6">
                <label
                  htmlFor="email"
                  className="mb-2 block text-sm font-medium italic text-neutral-600 dark:text-neutral-600"
                >
                  Email address
                </label>
                <input
                  type="email"
                  id="email"
                  className="block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-sm text-gray-900 focus:border-blue-500 focus:ring-blue-500 dark:border-gray-400 dark:bg-gray-100 dark:text-neutral-600 dark:placeholder-gray-400 dark:focus:border-blue-500 dark:focus:ring-blue-500"
                  placeholder="siit.innoclub@gmail.com"
                  required
                />
              </div>
              <div className="mb-6">
                <label
                  htmlFor="Subject"
                  className="mb-2 block text-sm font-medium italic text-neutral-600 dark:text-neutral-600"
                >
                  Subject
                </label>
                <input
                  type="Subject"
                  id="Subject"
                  className="block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-sm text-gray-900 focus:border-blue-500 focus:ring-blue-500 dark:border-gray-400 dark:bg-gray-100 dark:text-neutral-600 dark:placeholder-gray-400 dark:focus:border-blue-500 dark:focus:ring-blue-500"
                  placeholder="what's you would like to contact us"
                  required
                />
              </div>

              <div className="mb-6">
                <label
                  htmlFor="Message"
                  className="mb-2 block text-sm font-medium italic text-neutral-600 dark:text-neutral-600"
                >
                  Message
                </label>
                <input
                  type="Message"
                  id="Message"
                  className="dark:text-neutral-600e block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-sm text-gray-900 focus:border-blue-500 focus:ring-blue-500 dark:border-gray-400 dark:bg-gray-100 dark:placeholder-gray-400 dark:focus:border-blue-500 dark:focus:ring-blue-500"
                  placeholder="Write your messages here"
                  required
                />
              </div>
            </form>

            <a href="/projects">
              <button className="md:text-md hover:text-bold mt-10 flex items-center gap-2 rounded-full bg-yellow-300 px-5 py-[10px] text-center text-sm font-semibold text-[#727272] duration-150 ease-in hover:scale-100 hover:bg-yellow-400 hover:text-white md:px-8 md:shadow-md">
                Submit
              </button>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}

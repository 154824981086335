import React, { useState } from "react";
import { NavLink } from "react-router-dom";

export default function HeaderProjectDetail() {
  const liFont: string =
    "text-sm sm:text-sm md:text-[0.93rem] lg:text-[1.05rem] xl:text-lg 4xl:text-xl font-nav tracking-wide 4xl;tracking-wider text-gray-600";
  const liFontMobile: string =
    "text-lg font-nav tracking-wide 4xl;tracking-wider text-gray-600  border-gray border-b-2 pb-2 pt-1 ";

  // to change burger classes
  const [burger_class, setBurgerClass] = useState("burger-bar unclicked");
  const [menu_class, setMenuClass] = useState("menu hidden");
  const [isMenuClicked, setIsMenuClicked] = useState(false);

  // toggle burger menu change
  const updateMenu = () => {
    setTimeout(() => {
      if (!isMenuClicked) {
        setBurgerClass("burger-bar clicked");
        setMenuClass("menu visible");
      } else {
        setBurgerClass("burger-bar unclicked");
        setMenuClass("menu hidden");
      }
      setIsMenuClicked(!isMenuClicked);
    }, 300);
  };

  return (
    <div>
      <nav>
        <ul className="fixed top-0 z-50 flex w-full items-center justify-between space-x-1 bg-white px-4 pt-0 drop-shadow-lg sm:p-4">
          <li>
            <NavLink to="/">
              <img
                className="w-16 sm:w-20 4xl:w-24"
                src="/image/sinnoLogo.png"
                alt="club logo"
              ></img>
            </NavLink>
          </li>

          <div className="mt-6 h-[3rem] p-1 sm:hidden">
            <div className="burger-menu md:hidden" onClick={updateMenu}>
              <div className={burger_class}></div>
              <div className={burger_class}></div>
              <div className={burger_class}></div>
            </div>
          </div>

          <div className="hidden sm:container sm:mx-auto sm:flex sm:w-80 sm:items-center sm:justify-between md:w-[20rem] lg:w-[24rem] 4xl:w-[29rem]">
            <li className={liFont}>
              <NavLink to="/">About us</NavLink>
            </li>
            <li className={liFont}>
              <NavLink to="/contribution">Contributions</NavLink>
            </li>
            <li className={liFont}>
              <NavLink to="/community">Community</NavLink>
            </li>
          </div>
        </ul>
      </nav>

      <div className={menu_class}>
        <ul className="mt-28 grid gap-y-7 px-7">
          <li className={liFontMobile}>
            <NavLink to="/">About us</NavLink>
          </li>
          <li className={liFontMobile}>
            <NavLink to="/contribution">Contributions</NavLink>
          </li>
          <li className={liFontMobile}>
            <NavLink to="/community">Community</NavLink>
          </li>
        </ul>
      </div>
    </div>
  );
}

import React, { useState, useEffect, ReactNode, CSSProperties } from "react";

interface LazyBackgroundProps {
  src: string;
  className: string;
  children: ReactNode;
  style?: CSSProperties;
}

const LazyBackground: React.FC<LazyBackgroundProps> = ({
  src,
  className,
  children,
  ...props
}) => {
  const [imageSrc, setImageSrc] = useState<string | null>(null);

  useEffect(() => {
    const img = new Image();
    img.src = src;
    img.onload = () => setImageSrc(src);
  }, [src]);

  return (
    <div
      {...props}
      style={{
        backgroundImage: `url(${imageSrc || "./path/to/placeholder.jpg"})`,
        ...props.style,
      }}
      className={className}
    >
      {children}
    </div>
  );
};

export default LazyBackground;

import React from "react";
import {
  HoverCard,
  HoverCardContent,
  HoverCardTrigger,
} from "../shadui/HoverCard.tsx";
import { Link } from "react-router-dom";

export default function MemberCard({
  id,
  name,
  role,
  profileImage,
  githubUrl,
}: {
  id: number;
  name: string;
  role: string;
  profileImage: string;
  githubUrl: string;
}) {
  return (
    <>
      <HoverCard>
        <HoverCardTrigger>
          <div
            key={id}
            className="flex w-full cursor-pointer items-center justify-between space-x-5 rounded-[50px] bg-white p-1 shadow-2xl hover:bg-[#FFBF00] md:space-x-3 lg:px-5 lg:py-2 4xl:space-x-5 4xl:py-3"
          >
            <div
              className="h-[7.2vmax] w-[7.2vmax] rounded-full bg-cover lg:h-14 lg:w-14 xl:h-18 xl:w-16 4xl:h-20 4xl:w-20"
              style={{
                backgroundImage: `url("${
                  profileImage || "/image/default-profile-pic.jpg"
                }")`,
              }}
            ></div>

            <div className="hidden lg:flex lg:min-w-0 lg:flex-1 lg:flex-col">
              <p className="truncate text-[0.6rem] font-medium text-black xl:text-[0.75rem] 4xl:text-base">
                {name.split(" ")[0]}
              </p>
              <p className="truncate text-[0.6rem] font-medium text-black xl:text-[0.75rem] 4xl:text-base">
                {name.split(" ")[1]}
              </p>
            </div>
          </div>
        </HoverCardTrigger>

        <HoverCardContent className="space-y-1 bg-white">
          <p className="w-full text-[0.7rem] font-bold text-gray-500 xl:text-xs">
            Name: <span className="text-[#8338EC]">{name}</span>
          </p>
          <p className="text-[0.7rem] font-bold text-gray-500 xl:text-xs">
            Interest:{" "}
            {role ? (
              <span className="text-[#8338EC]">
                {role.replace("[", "").replace("]", "")}
              </span>
            ) : (
              <span className="text-gray-500"> - </span>
            )}
          </p>
          {githubUrl ? (
            <Link
              to={githubUrl ? githubUrl : "#"}
              target="_blank"
              className="text-[0.7rem] font-bold text-gray-500 xl:text-xs"
            >
              Github:{" "}
              <span className="text-[#8338EC] hover:underline">
                {githubUrl}
              </span>
            </Link>
          ) : (
            <p className="text-[0.7rem] font-bold text-gray-500 xl:text-xs">
              {" "}
              Github: -{" "}
            </p>
          )}
        </HoverCardContent>
      </HoverCard>
    </>
  );
}

import React, { useState, useEffect, useCallback } from "react";

export default function Carousel({
  slides,
  header,
}: {
  slides: { image: string }[];
  header: boolean;
}) {
  const [curr, setCurr] = useState<number>(0);

  const nextSlide = useCallback(() => {
    setCurr((curr) => (curr === slides.length - 1 ? 0 : curr + 1));
  }, [slides.length]);

  useEffect(() => {
    const slideInterval = setInterval(nextSlide, 3000);
    return () => clearInterval(slideInterval);
  }, [nextSlide]);

  return (
    <div
      className={`relative h-full overflow-hidden rounded-md ${
        header ? "" : "rounded-3xl shadow-md"
      }`}
    >
      <div
        className="flex h-full w-full transition-transform duration-500 ease-out"
        style={{ transform: `translateX(-${curr * 100}%)` }}
      >
        {slides.map((item) => {
          return (
            <img
              src={item.image}
              className="aspect-[6.5/1] h-full w-full object-cover"
              alt="slide_img"
            />
          );
        })}
      </div>
      <div
        className={`${
          header ? "" : "hidden"
        }absolute top-0 flex h-full w-full items-center justify-between px-6 text-7xl text-white`}
      ></div>
      <div className="absolute bottom-4 left-0 right-0">
        <div className="flex items-center justify-center gap-2">
          {slides.map((_, index) => (
            <div
              onClick={() => {
                setCurr(index);
              }}
              className={`h-3 w-3 cursor-pointer rounded-full transition-all ${
                curr === index ? "bg-[#646464]" : "border border-[#646464]"
              }`}
            ></div>
          ))}
        </div>
      </div>
    </div>
  );
}

export const footerData = {
  description:
    "SIIT Innovation Club, a student organization at Sirindhorn International Institute of Technology (SIIT), Thammasat University.",
  email: "siit.innoclub@gmail.com",
  phone: "0649915393",
  link: {
    facebook: "https://www.instagram.com/sinnoclub.official/",
    instagram: "https://www.instagram.com/sinnoclub.official/",
    linkedin:
      "https://www.linkedin.com/company/siit-innovation-club/mycompany/",
    github: "https://github.com/SIITInnovationClub/",
  },
};

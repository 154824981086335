import React from "react";

export default function ContributionCard({
  title,
  image_urls,
  description,
}: {
  title: string;
  image_urls: string;
  description: string;
}) {
  return (
    <>
      <div>
        <img
          src={image_urls}
          alt="contribution_img"
          className="h-[200px] w-full rounded-xl object-cover shadow-md 3xl:h-[300px] 4xl:h-[320px]"
        />
      </div>
      <div className="ml-2 mt-3 text-[#727272]">
        <h3 className="mt-6 text-[1.05rem] font-normal text-[#4c4b4b] md:text-[1.3rem]">
          {title}
        </h3>
        <p className="mt-2 text-[0.9rem]">{description.slice(0, 120)} ...</p>
      </div>
    </>
  );
}

import React, { useEffect, useState } from "react";
import { useParams, Link } from "react-router-dom";
import axios from "axios";
import HeaderStatic from "../components/HeaderStatic.tsx";
import Footer from "../components/Footer.tsx";
import Carousel from "../components/contributionDetail/Carousel.tsx";
import {
  ContributionType,
  ContributionIDType,
} from "../data/contributionDetail.ts";
import ContributionDetailCard from "../components/contributionDetail/ContributionDetailCard.tsx";
import "../index.css";
import { GoTriangleLeft } from "react-icons/go";
import { FaAngleLeft, FaAngleRight } from "react-icons/fa6";
import { FaLink } from "react-icons/fa";
import {
  HoverCard,
  HoverCardContent,
  HoverCardTrigger,
} from "../components/shadui/HoverCard.tsx";

const ContributionDetail = () => {
  const { id } = useParams<string>();
  const [allContribution, setAllContribution] = useState<ContributionType[]>(
    [],
  );
  const [thisContribution, setThisContributor] = useState<ContributionIDType>();

  useEffect(() => {
    axios
      .get(`/api/events`)
      .then((res) => setAllContribution(res.data.data))
      .catch((err) => console.error(`Error fetching data ${err}`));
  }, []);

  useEffect(() => {
    axios
      .get(`/api/events/${id}`)
      .then((res) => setThisContributor(res.data.data))
      .catch((err) => console.error(`Error fetching data ${err}`));
  }, [id]);

  const moreContributions = allContribution.sort(() => Math.random() - 0.5);

  const slides = [
    {
      image: thisContribution?.image_urls || "",
    },
  ];

  return (
    <div className="overflow-x-hidden">
      <HeaderStatic />

      {thisContribution && (
        <div>
          {/*Container*/}
          <div className="w-full py-20 md:px-12 md:py-24">
            {/*Go back*/}
            <div className="hidden w-fit cursor-pointer rounded-full border border-transparent px-4 py-2 duration-100 ease-in hover:border-gray-400 md:inline-block">
              <Link to={`/contribution`}>
                <button className="flex items-center text-[#9D9D9D]">
                  <GoTriangleLeft />
                  <span className="ml-2 text-[#7B7B7B]">
                    Back to Contributions
                  </span>
                </button>
              </Link>
            </div>

            {/*Contribution details*/}
            <div className="mt-4 flex flex-col items-center px-8 text-[#3d3d3d] md:px-16">
              {/*Group for mobile*/}
              <div className="flex-col text-center">
                <h1 className="col-start-1 row-start-1 mb-2 pt-8 text-4xl md:my-5 md:text-6xl">
                  {" "}
                  {thisContribution.title}{" "}
                </h1>

                <div className="row-start-2 flex h-fit justify-center gap-4 pb-1 md:pb-5 md:pt-0">
                  {thisContribution?.tags?.map((tag) => (
                    <p
                      className={`rounded-xl border-2 border-[#FF3333] px-2 py-[.1rem] text-xs font-medium text-[#FF3333] md:rounded-xl md:text-base lg:px-3 lg:py-0`}
                    >
                      {tag}
                    </p>
                  ))}
                </div>

                <p className="row-start-6 py-3 text-sm md:mb-6 md:text-lg">
                  {" "}
                  {thisContribution.date_of_announcement}{" "}
                </p>

                {/*Poster Slider*/}
                <div className="col-span-1 row-span-6 row-start-1 h-[36vh] md:mx-auto md:h-[40vh] xl:h-[60vh]">
                  <Carousel
                    slides={slides.map((slide) => slide)}
                    header={true}
                  />
                </div>

                {/*Contributors (Mobile)*/}
                <div className="row-start-5 flex w-full flex-col items-center justify-center px-2 md:mt-5 md:hidden md:items-end">
                  {thisContribution?.members.length > 0 && (
                    <p className="xl:text-md pt-7 text-sm font-medium text-[#8338EC] md:pt-0">
                      Contributors
                    </p>
                  )}

                  <div className="flex -space-x-2 overflow-hidden pt-2 md:pt-0">
                    {thisContribution?.members
                      ?.slice(0, 4)
                      .map((contibutor, index) => (
                        <img
                          key={index}
                          className="inline-block h-8 w-8 cursor-pointer rounded-full ring-2 ring-white 4xl:h-10 4xl:w-10"
                          src={
                            contibutor.profile_image_url ||
                            "/image/default-profile-pic.jpg"
                          }
                          alt="contribor_image"
                          onError={(
                            e: React.SyntheticEvent<HTMLImageElement, Event>,
                          ) => {
                            (e.target as HTMLImageElement).src =
                              "/image/default-profile-pic.jpg";
                          }}
                        />
                      ))}
                    {thisContribution?.members?.length > 4 && (
                      <p className="hover:bg-gray-6000 flex h-8 w-8 items-center justify-center rounded-full bg-[#D9D9D9] text-xs font-medium text-[#7A7A7A] ring-2 ring-white 4xl:h-10 4xl:w-10">
                        +{thisContribution?.members?.length - 4}
                      </p>
                    )}
                  </div>
                </div>
              </div>

              {/*Poster Summary*/}
              <div className="mt-10 flex flex-col gap-4">
                <h3 className="mb-4 hidden text-3xl md:block">Summary</h3>
                <p className="text-gray-500">{thisContribution?.description}</p>
                {thisContribution?.link && (
                  <div className="mt-3 flex items-center">
                    <FaLink className="text-gray-700" />{" "}
                    <Link
                      to={thisContribution?.link}
                      className="ml-2 font-medium text-gray-700 hover:text-[#8338EC] hover:underline"
                    >
                      {thisContribution?.link}
                    </Link>
                  </div>
                )}
              </div>

              {/*Contributors (Desktop)*/}
              <div className="mt-10 hidden w-full flex-col items-end pr-16 md:flex">
                {thisContribution?.members.length > 0 && (
                  <p className="text-lg font-medium text-[#8338EC]">
                    Contributors
                  </p>
                )}
                {thisContribution && (
                  <HoverCard>
                    <HoverCardTrigger>
                      <div className="mt-1 flex -space-x-2 overflow-hidden">
                        {thisContribution.members
                          ?.slice(0, 4)
                          .map((contributor, index) => (
                            <img
                              key={index}
                              className="inline-block h-10 w-10 cursor-pointer rounded-full ring-2 ring-white 4xl:h-12 4xl:w-12"
                              src={
                                contributor.profile_image_url ||
                                "/image/default-profile-pic.jpg"
                              }
                              alt="contributor_image"
                              onError={(
                                e: React.SyntheticEvent<
                                  HTMLImageElement,
                                  Event
                                >,
                              ) => {
                                (e.target as HTMLImageElement).src =
                                  "/image/default-profile-pic.jpg";
                              }}
                            />
                          ))}
                        {thisContribution.members?.length > 4 && (
                          <Link
                            className="hover:bg-gray-6000 flex h-10 w-10 items-center justify-center rounded-full bg-[#D9D9D9] text-xs font-medium text-[#7A7A7A] ring-2 ring-white 4xl:h-12 4xl:w-12"
                            to="#"
                          >
                            +{thisContribution.members.length - 4}
                          </Link>
                        )}
                      </div>
                    </HoverCardTrigger>
                    <HoverCardContent className="bg-white">
                      <ul className="list-decimal pl-3 font-medium lg:text-sm">
                        {thisContribution?.members?.map(
                          (contributor, index) => (
                            <li key={index}>{contributor.name}</li>
                          ),
                        )}
                      </ul>
                    </HoverCardContent>
                  </HoverCard>
                )}
              </div>

              {/*Contribution Navigator*/}
              <div className="mt-16 flex w-[90%] justify-between px-2 lg:px-16">
                {thisContribution?.previous_url !== null ? (
                  <Link
                    to={`/contribution/${thisContribution?.previous_url}`}
                    className="cursor-pointer duration-200 ease-in hover:scale-105"
                  >
                    <div className="flex items-center gap-1 text-[#3C3C3C]">
                      <div className="text-xl">
                        <FaAngleLeft />
                      </div>
                      <p className="text-md">Previous</p>
                    </div>
                    <p className="ml-1 mt-2 hidden text-xl md:inline-block lg:text-2xl">
                      {thisContribution?.previous_title}
                    </p>
                  </Link>
                ) : (
                  <Link to="#">
                    <div className="flex items-center gap-1 text-transparent">
                      <div className="text-xl">
                        <FaAngleLeft />
                      </div>
                      <p className="text-md">Previous</p>
                    </div>
                  </Link>
                )}

                {thisContribution?.next_url !== null ? (
                  <Link
                    to={`/contribution/${thisContribution?.next_url}`}
                    className="flex cursor-pointer flex-col items-end duration-200 ease-in hover:scale-105"
                  >
                    <div className="flex items-center gap-1 text-[#3C3C3C]">
                      <p className="text-md text-right">Next</p>
                      <div className="text-xl">
                        <FaAngleRight />
                      </div>
                    </div>
                    <p className="mr-1 mt-2 hidden text-right text-xl md:inline-block lg:text-2xl">
                      {thisContribution?.next_title}
                    </p>
                  </Link>
                ) : (
                  <Link
                    to="#"
                    className="flex cursor-pointer flex-col items-end duration-200 ease-in hover:scale-105"
                  >
                    <div className="flex items-center gap-1 text-transparent">
                      <p className="text-md text-right">Next</p>
                      <div className="text-xl">
                        <FaAngleRight />
                      </div>
                    </div>
                  </Link>
                )}
              </div>
            </div>
          </div>
        </div>
      )}

      {/*Other Contribution*/}
      <div className="mb-8 overflow-hidden md:mt-24">
        <div className="flex w-screen items-center justify-between px-10 md:px-16">
          <h2 className="text-2xl text-[#727272] md:text-3xl">
            Other Contributions
          </h2>
          <Link to="/contribution">
            <p className="mr-4 hidden cursor-pointer text-xl text-[#727272] underline hover:text-indigo-400 md:block">
              See all
            </p>
          </Link>
        </div>

        <div className="w-full px-10 md:px-16">
          <div className="scrollbar-thumb flex items-center overflow-x-scroll pb-5 scrollbar-thin scrollbar-track-[#EDEDED] scrollbar-thumb-[#7A7A7A] scrollbar-track-rounded-full scrollbar-thumb-rounded-full">
            {moreContributions.slice(0, 8).map((moreContribution) =>
              moreContribution.alias !== id ? (
                <Link
                  to={`/contribution/${moreContribution.alias}`}
                  key={moreContribution.id}
                >
                  <ContributionDetailCard
                    title={moreContribution.title}
                    date_of_announcement={moreContribution.date_of_announcement}
                    event_type={moreContribution.event_type}
                    image_urls={moreContribution.image_urls}
                    description={moreContribution.description}
                  />
                </Link>
              ) : null,
            )}
          </div>
        </div>
      </div>

      <br></br>
      <Footer></Footer>
    </div>
  );
};

export default ContributionDetail;

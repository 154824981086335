import React from "react";

export default function ContributionCard({
  title,
  date_of_announcement,
  event_type,
  image_urls,
  description,
}: {
  title: string;
  date_of_announcement: string;
  event_type: string;
  image_urls: string;
  description: string;
}) {
  const [hover, setHover] = React.useState<boolean>(false);

  const handleMouseEnter = () => {
    setTimeout(() => {
      setHover(true);
    }, 200);
  };

  const handleMouseLeave = () => {
    setTimeout(() => {
      setHover(false);
    }, 200);
  };

  return (
    <div
      className="relative mt-10 flex h-64 w-88 cursor-pointer rounded-2xl bg-cover bg-center drop-shadow-md sm:h-64 sm:w-72 md:h-68 md:w-[22rem] lg:mt-7 lg:h-64 lg:w-80 lg:duration-500 lg:hover:-translate-y-2 xl:h-68 xl:w-96 4xl:h-[17vmax] 4xl:w-[23vmax]"
      style={{
        backgroundImage: `url(${image_urls})`,
      }}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <div className="relative z-10 mt-auto w-full rounded-b-2xl bg-black/[.5] p-2 lg:p-5">
        <h2 className="mt-auto text-base font-semibold text-white md:text-lg">
          {title}
        </h2>
        <div className="mt-1 flex items-center space-x-3">
          <p className="text-xs text-gray-400 lg:text-sm">
            {date_of_announcement}
          </p>
          <p
            className={`rounded-xl border-[1.5px] border-[#FFC1C1] px-2 py-[.1rem] text-[.7rem] font-medium text-[#FFC1C1] md:py-1 md:text-xs`}
          >
            {event_type}
          </p>
        </div>
        {hover && (
          <p className="my-4 text-xs text-white transition-opacity md:text-sm">
            {description.slice(0, 200)}...
          </p>
        )}
      </div>
    </div>
  );
}

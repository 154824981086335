export type AnnouncementType = {
  alias: string;
  id: number;
  title: string;
  event_type: string;
  description: string;
  date_of_announcement: string;
  image_urls: string;
  data: any;
  contributor?: string[];
};

export type ContributionType = {
  alias: string;
  id: number;
  title: string;
  event_type: string;
  description: string;
  date_of_announcement: string;
  image_urls: string;
  data: any;
  contributor?: string[];
};

export type memberType = {
  id: number;
  name: string;
  role: string;
  profile_url: string;
  profile_image_url: string;
};

export const AboutSinno: string = `
Welcome to the SIIT Innovation Club (SINNO) at Sirindhorn International Institute of Technology (SIIT), where creativity meets collaboration to drive impactful 
projects. Our motto, "Creative, Collaborative, Project-Driven," reflects our mission to nurture innovative ideas and foster teamwork among students from diverse
 backgrounds. We focus on turning concepts into reality through hands-on projects, helping students enhance their abilities for the long run. Join us to explore new ideas, 
 work together, and be part of a dynamic community where your creativity and teamwork lead to meaningful change.
`;

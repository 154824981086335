export const description: string = `Our club makes significant contributions through various activities. Our workshops provide essential knowledge and hands-on learning experiences, 
giving students practical experience that are useful for their future career. Through diverse projects, members turn creative ideas into real solutions for everyday challenges.
Additionally, we aim to host competitions that not only showcase our talents but also drive innovative ideas from our participants.. These activities emphasize learning through practical experience,
  helping students improve their skills and personal growth.`;

export type ContributionType = {
  alias: string;
  id: number;
  title: string;
  event_type: string;
  description: string;
  date_of_announcement: string;
  image_urls: string;
};

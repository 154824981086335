import React from "react";
import { Link } from "react-router-dom";
import { FaFacebook, FaInstagram, FaLinkedin } from "react-icons/fa6";
import { FaGithub } from "react-icons/fa";
import { MdOutlineMail, MdPhone } from "react-icons/md";
import { footerData } from "../data/footer.ts";

export default function Footer() {
  return (
    <div>
      <div className="hidden md:mt-20 md:grid md:grid-cols-3 md:justify-items-center md:gap-10 md:border-t-2 md:px-20 md:pt-7">
        <div className="max-w-xs">
          <h2 className="mb-2 text-xl text-neutral-600 4xl:text-2xl">
            SINNO CLUB
          </h2>
          <p className="mt-1 4xl:text-xl">{footerData.description}</p>
        </div>

        <div className="max-w-xs">
          <h1 className="mb-2 text-xl text-neutral-600 4xl:text-2xl">
            Contact us
          </h1>
          <p className="flex items-center 4xl:text-xl">
            <span className="mr-2 text-lg 4xl:text-xl">
              <MdOutlineMail></MdOutlineMail>
            </span>
            {footerData.email}
          </p>
          <p className="flex items-center 4xl:text-xl">
            <span className="mr-2 text-lg 4xl:text-xl">
              <MdPhone></MdPhone>
            </span>
            {footerData.phone}
          </p>
        </div>

        <div className="flex-col">
          <h1 className="text-xl text-neutral-600 4xl:text-2xl">Follow us</h1>
          <ul className="mt-2 grid max-w-fit grid-cols-4 gap-5">
            <li className="duration-10 flex cursor-pointer justify-center rounded-full border border-[#7B7B7B] pt-[.65rem] ease-in hover:bg-[#EEEEEE] md:h-12 md:w-12 lg:h-14 lg:w-14 lg:pt-[.8rem]">
              <Link
                to={footerData.link.instagram}
                target="_blank"
                rel="noreferrer"
                className="text-[1.5rem] lg:text-[1.7rem]"
              >
                <FaInstagram></FaInstagram>
              </Link>
            </li>

            <li className="duration-10 flex cursor-pointer justify-center rounded-full border border-[#7B7B7B] pt-[.65rem] ease-in hover:bg-[#EEEEEE] md:h-12 md:w-12 lg:h-14 lg:w-14 lg:pt-[.8rem]">
              <Link
                to={footerData.link.linkedin}
                target="_blank"
                rel="noreferrer"
                className="text-[1.5rem] lg:text-[1.7rem]"
              >
                <FaLinkedin></FaLinkedin>
              </Link>
            </li>

            <li className="duration-10 flex cursor-pointer justify-center rounded-full border border-[#7B7B7B] pt-[.65rem] ease-in hover:bg-[#EEEEEE] md:h-12 md:w-12 lg:h-14 lg:w-14 lg:pt-[.8rem]">
              <Link
                to={footerData.link.github}
                target="_blank"
                rel="noreferrer"
                className="text-[1.5rem] lg:text-[1.7rem]"
              >
                <FaGithub></FaGithub>
              </Link>
            </li>
          </ul>
        </div>
      </div>

      <div className="mt-10 grid grid-cols-1 bg-gray-200 px-[4vmax] md:hidden">
        <ul className="pb-5 pt-5 text-[0.94rem] font-semibold text-neutral-600">
          <Link to="/">
            <li className="border-2 border-b-slate-300 pb-2">About us</li>
          </Link>
          <Link to="/contribution">
            <li className="border-2 border-b-slate-300 pb-2 pt-1">
              Contributions
            </li>
          </Link>
          <Link to="/community">
            <li className="border-2 border-b-slate-300 pb-2 pt-1">Community</li>
          </Link>
        </ul>

        <div className="my-3 grid grid-cols-2 gap-x-5">
          <div>
            <h2 className="text-[0.94rem] text-neutral-600">SINNO CLUB</h2>
            <p className="mt-1 text-xs">{footerData.description}</p>
          </div>

          <div>
            <h1 className="mb-2 text-[0.94rem] text-neutral-600">Contact us</h1>
            <p className="flex items-center text-xs">
              <span className="mr-2 text-xs">
                <MdOutlineMail></MdOutlineMail>
              </span>
              {footerData.email}
            </p>
            <p className="flex items-center text-xs">
              <span className="mr-2 text-xs">
                <MdPhone></MdPhone>
              </span>
              {footerData.phone}
            </p>
          </div>
        </div>

        <div className="flex-col py-5">
          <h1 className="text-md text-neutral-600">Follow us</h1>
          <ul className="mt-2 grid max-w-fit grid-cols-4 gap-5">
            <li className="duration-10 flex h-10 w-10 cursor-pointer justify-center rounded-full border border-[#7B7B7B] pt-[.45rem] ease-in hover:bg-[#EEEEEE]">
              <Link
                to={footerData.link.instagram}
                target="_blank"
                rel="noreferrer"
                className="text-[1.4rem]"
              >
                <FaInstagram></FaInstagram>
              </Link>
            </li>

            <li className="duration-10 flex h-10 w-10 cursor-pointer justify-center rounded-full border border-[#7B7B7B] pt-[.45rem] ease-in hover:bg-[#EEEEEE]">
              <Link
                to={footerData.link.linkedin}
                target="_blank"
                rel="noreferrer"
                className="text-[1.4rem]"
              >
                <FaLinkedin></FaLinkedin>
              </Link>
            </li>

            <li className="duration-10 flex h-10 w-10 cursor-pointer justify-center rounded-full border border-[#7B7B7B] pt-[.45rem] ease-in hover:bg-[#EEEEEE]">
              <Link
                to={footerData.link.github}
                target="_blank"
                rel="noreferrer"
                className="text-[1.4rem]"
              >
                <FaGithub></FaGithub>
              </Link>
            </li>
          </ul>
        </div>
      </div>

      <p className="bg-gray-200 px-[4vmax] pb-10 pt-5 text-center text-[0.55rem] leading-5 text-gray-400 md:mx-32 md:mt-12 md:bg-transparent md:text-xs 4xl:text-sm">
        COPYRIGHT © 2021 INNOVATION CLUB OF SIRINDHORN INTERNATIONAL INSTITUTE
        OF TECHNOLOGY, THAMMASAT UNIVERSITY. ALL RIGHT RESERVED.
      </p>
    </div>
  );
}
